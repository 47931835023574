.cancelButton {
  padding: 10px 28px 10px 28px;
  border: 1px solid #ece9f5;
  border-radius: 8px;
  background-color: white;
  color: #050c46;
  font-weight: 600;
}

.applyButton {
  padding: 10px 35px 10px 35px;
  border: 1px solid #ece9f5;
  border-radius: 8px;
  background-color: #5761fe;
  color: white;
  margin-left: 5px;
}

.DateContainer {
  display: flex;
  align-items: center;
  width: 130px;
  height: 40px;
  border: 1px solid #ece9f5;
  border-radius: 5px;
  color: "#050C46";
  margin-left: 5px;
}

.dateInput {
  width: 100%;
  height: 100%;
}

.dateInput input {
  width: calc(100% + 25px);
  margin: 10px 0px 0px 4px;
  border: none;
  background: transparent;
  color: "#050C46";
  z-index: 1;
}

.icon {
  margin: 0px 10px 4px 7px;
  height: 20px;
  width: 20px;
  color: "#050C46";
}

.selected_day {
  color: #fff;
  background-color: #00aa38;
  border-radius: 20px;
}

.react-datepicker__header {
  background: white;
  border-bottom: none;
  color: "#050C46";
}

textarea:focus,
input:focus {
  outline: none;
}

.apexcharts-tooltip {
  background: #050c46;
  color: #fefefe;
}

.apexcharts-tooltip-title {
  background-color: #050c46;
  color: #fefefe;
  border-color: #050c46;
}

.apexcharts-toolbar-download-icon {
  padding-right: 80px;
}
.loader-scroll .MuiCircularProgress-root {
  width: 55px !important;
  height: 55px !important;
}
.loader-scroll {
  width: 100%;
  height: 100% !important;
}

@media screen and (max-width: 300px) {
  .DateBlock {
    flex-direction: column;
    /* margin-right: 5px; */
  }
}
