.basicDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #111927;
}

.subHeadding {
 
  font-size: 16px;
}

.title {
  color: #111927;
  font-size: 14px;
}

.subTitle {
  font-weight: 500;
  font-size: 14px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid rgba(224, 224, 229, 1);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  background-color: rgba(255, 255, 255, 1);
}

.dropZoneArea {
  width: 100%;
  height: 128px;
  border: 1px dashed #b3b5c7;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.imageLoader {
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  background: #fff;
  opacity: 70%;
  border-radius: 12px;
}

.navigation {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: max-content;
  display: block;
  /* margin-left: 40px; */
  /* margin-right: 50px; */
  z-index: 2;
  position: sticky;
  transform: translateX(-30%);
  top: 15%;
  display: flex;
  border-radius: 3rem;
}

.activeMenuList {
  background-color: rgb(220 221 239);
  margin: "20px";
}

.unactiveMenuList {
  background-color: transparent;
  margin-bottom: "20px";
  margin-top: "20px";
}

.listLink {
  color: #111927;
  font-weight: 500;
  border-radius: 8px;
  font-size: 14px;
  text-decoration: none;
  margin: 10px;
  padding: 5px 20px 5px 10px;
  border-radius: 6px;
}

.uploadButton {
  color: #5761fe;
  font-size: 14px;
  border: none;
  background-color: transparent;
  font-weight: 700;
}

.dragText {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #55597a;
  font-weight: 400;
}

.svgText {
  font-size: 14px;
  margin-top: 0px;
  color: #55597a;
  font-weight: 400;
}

.uploadIcon {
  margin-top: 5px;
  height: 45px;
  width: 45px;
}

@media screen and (max-width: 900px) {
  .mainContainer {
    flex-direction: column;
    /* margin-right: 5px; */
  }
}

.additional-btn {
  background: #2970ff !important;
  color: #fff !important;
  margin: 10px 0 !important;
  height: 34px !important;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.field-additional input {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  color: #000;
  font-family: "Inter";
  max-width: 160px;
  height: 40px;
  line-height: 40px;
}

.field-additional button {
  background: #2970ff !important;
  color: #fff !important;
  height: 34px;
  border: 1px solid #2970ff !important;
  border-radius: 10px;
  margin-left: 10px;
}
html .cancel-additional {
  background: transparent !important;
  border: 1px solid #2970ff !important;
  color: #2970ff !important;
}
.field-additional input:focus {
  outline: none !important;
}
.field-additional input::-webkit-outer-spin-button,
.field-additional input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.agent-span {
  color: #dd0000;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.quantity-div {
  position: relative;
}
.quantity-div {
  position: relative;
}

span.minus-icon {
  position: absolute;
  left: 20px !important;
  top: 43px !important;
  cursor: pointer;
}

.field-additional button {
  width: 100%;
  margin: 0;
  margin-top: 10px;
  height: 40px;
  text-transform: capitalize;
}
.quantity-div input {
  text-align: center;
}
.quantity-div h4 {
  margin-bottom: 5px;
  text-align: center;
  color: #000;
  /* font-family: "DM Sans"; */
  font-weight: 500;
  margin-top: 4px;
}

.quantity-div {
  background: #f8f7fc;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px 10px;
}
span.plus-icon {
  position: absolute;
  right: 20px;
  top: 43px;
  cursor: pointer;
}

.quantity-div {
  position: relative;
}

span.minus-icon {
  position: absolute;
  left: 10px;
  top: 9px;
}

.field-additional button {
  width: 100%;
  margin: 0;
  margin-top: 10px;
  height: 40px;
  z-index: 9;
  position: relative;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .addit-div {
    width: 40%;
  }

  .feature-div {
    width: 60%;
  }
}


/*=========*/
.payment-title {
  line-height: 1.5;
  font-family: "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  color: #111927;
  font-weight: 700;
  font-size: 16px;
}

.payment-card-wrap {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid rgb(228, 228, 229);
  border-radius: 8px;
  flex-direction: column;
  margin-bottom: 16px;
}

p.card-info, p.name-on-card {
  margin: 0px 0px 4px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: rgb(129, 129, 176);
  font-weight: 400;
  font-size: 13px;
  line-height: 16.38px;
}

p.name-on-card {}

.card-code, .card-name {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: rgb(40, 40, 123);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

p.card-code {}

.card-code, .card-name {
  margin-top: 0;
}

p.card-name {
  margin-bottom: 0;
}

.payment-btn {
  text-align: center;
}

.payment-update {
  border: 1px solid rgba(33, 111, 237, 0.5);
  color: rgb(33, 111, 237);
  font-weight: 600;
  font-size: 14px;
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 1.75;
  padding: 5px 15px;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
}

button.payment-update:hover {
  background: #fafafa;
}

.loader-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #0003;
  width: 100%;
  height: 100%;
}    


.spinLoder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-tab .MuiGrid-item {
  max-width: 30% !important;
}

.custom-tab {
  justify-content: center;
}

.pop-authenticate-domain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dfdfdf;
  padding: 0 10px 0 10px;
  border-radius: 9px;
  flex-flow: row wrap;
}

.pop-authenticate-domain pre {
  background: #fcfcfc !important;
  white-space: unset !important;
}
p.black-authencate {
  margin: 0;
  color:rgb(0, 43, 54);
  margin-bottom: 2px;
}
p.red-authencate {
  margin: 0;
  color: rgb(203, 75, 22);
} 

@media (min-width: 600px) {
  .css-1pziig-MuiCardContent-root {
      width: 240px !important;
  }
}

@media (max-width: 600px) {
  .custom-tab .MuiGrid-item {
      max-width: 100% !important;
      width: 100% !important;
      flex-basis: 100% !important;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  font-size: 14px;
}

.card-name {
  padding: 0;
}

.card-link {
  display: block;
  padding: 8px;
  text-decoration: none;
  color: inherit;
}

.payment-card-wrap {
  margin-top: 20px; /* Adjust as needed */
}


.cancel-sub {
  padding-top: 0 !IMPORTANT;
  padding-right: 0 !important;
}
.subscription-wrap {
  justify-content: space-between;
}
.btn-modal button {
  background-color: #2970FF;
  border: 1px solid #2970FF;
  color: #fff;
  line-height: normal;
}
.btn-modal button:hover {
  color: #2970FF;
}
.model-heading {
  background-color: #dcddef;
  color: #000;
  margin-bottom: 15px !important;
}
p#alert-dialog-description {
  margin-top: 10px;
}
p#alert-dialog-description span {
  font-weight: bold;
  text-decoration: underline;
}
.btn-modal {
  margin-bottom: 10px;
}

@media (max-width:1300px) {
  .subscription-wrap .MuiButton-startIcon {
    display: none;
  }
  .cancel-sub button {
    width: max-content;
  }
}

@media (max-width:600px) {
  .subscription-wrap {
    flex-wrap: wrap;
  }
  .cancel-sub {
    margin-top: 20px;
  }
}

.css-ilynx3-MuiPaper-root-MuiDialog-paper{
  max-width: 730px  !important;
}

.custom-dialog .MuiPaper-elevation.MuiDialog-paperScrollPaper {
  width: 100%;
  max-width:500px  !important;
}