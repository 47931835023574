.createButton {
  color: #344054;
  font-size: 16px;
  font-weight: 600;
}
.CalendarSync__title,
.CalendarSync__add-toggle-text,
.CalendarSync__edit-toggle-text,
.AvailabilityViewer__calendars__title,
.AvailabilityViewer__submit {
  text-transform: capitalize;
}
