.apexcharts-tooltip {
    background:#050C46  ;
    color: #FEFEFE;
    
}

.apexcharts-tooltip-title{
    background-color:#050C46 ;
    color:#FEFEFE;
    border-color:#050C46;
   }

   .apexcharts-toolbar-download-icon {
    padding-right: 80px ;
}